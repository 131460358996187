import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { DashboardDataPoint } from '../../../shared/interfaces/dashboardDataPoint';
import { StoreService } from '../../../shared/services/storeService';
import { AnnualSalesFigure } from '../../../shared/interfaces/annualSalesFigure';
import { Store } from '../../../shared/interfaces/store';
import { Group } from '../../../shared/interfaces/group';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingService } from '../../../shared/services/settingService';

@Component({
    selector:'sales-dashboard',
    templateUrl: 'salesDashboard.component.html',
    styleUrls: ['salesDashboard.component.css']
})
export class SalesDashboardComponent implements OnInit {

    ngOnInit(): void {

        if (this.storeId) {
            this.storeService.loadStore(this.storeId)
                .subscribe(s => {
                    if (s) {
                        this.store = this.storeService.store;
                        this.group = this.store.groupName;
                        this.LoadDashboard();
                    }
                });

        }
        else {

            if (this.group) {
                this.LoadDashboard();
            }
        }

    }

    store: Store;

    @Input() storeId: string;
    @Input() group: string;

    sales: number;
    eyeTests: number;

    subtitle: string;

    showSales: boolean;
    showTurnover: boolean;
    showAllCustomers: boolean;
    showNewCustomers: boolean;
    showReturningCustomers: boolean;
    showAllEyeTests: boolean;
    showNewEyeTests: boolean;
    showReturningEyeTests: boolean;
    showToggle: boolean;
    dontShowToggle: boolean;
    showGrossProfitChart: boolean;
    showTransactions: boolean;
    showLp: boolean;

    customerTitle: string = "Customers";
    newCustomerTitle: string = "New Customers";
    returningCustomerTitle: string = "Returning Customers";
    eyeTestTitle: string = "Eye Tests";
    newEyeTestTitle: string = "New Eye Tests";
    returningEyeTestTitle: string = "Returning Eye Tests";
    salesTitle: string = "Sales";
    turnoverTitle: string = "Turnover";
    transactionsTitle: string = "Transactions";
    retailBasketSalesTitle: string = "Retail Basket Sales";
    retailBasketTransactionsTitle: string = "Retail Basket Transactions";
    averageTransactionValueTitle: string = "Average Invoice Value";
    averageRetailBasketTransactionValueTitle: string = "Average Retail Basket Value";
    lpPenetrationTitle: string = "LP Penetration";

    customerDataPoint: DashboardDataPoint = { id: "", priorYear: 0, currentYear: 0, growth: 0.0 };
    newCustomerDataPoint: DashboardDataPoint = { id: "", priorYear: 0, currentYear: 0, growth: 0.0 };
    returningCustomerDataPoint: DashboardDataPoint = { id: "", priorYear: 0, currentYear: 0, growth: 0.0 };
    eyeTestDataPoint: DashboardDataPoint = { id: "", priorYear: 0, currentYear: 0, growth: 0.0 };
    newEyeTestDataPoint: DashboardDataPoint = { id: "", priorYear: 0, currentYear: 0, growth: 0.0 };
    returningEyeTestDataPoint: DashboardDataPoint = { id: "", priorYear: 0, currentYear: 0, growth: 0.0 };
    salesDataPoint: DashboardDataPoint = { id: "", priorYear: 0, currentYear: 0, growth: 0.0 };
    turnoverDataPoint: DashboardDataPoint = { id: "", priorYear: 0, currentYear: 0, growth: 0.0 };
    transactionsDataPoint: DashboardDataPoint = { id: "", priorYear: 0, currentYear: 0, growth: 0.0 };
    retailBasketSalesDataPoint: DashboardDataPoint = { id: "", priorYear: 0, currentYear: 0, growth: 0.0 };
    retailBasketTransactionsDataPoint: DashboardDataPoint = { id: "", priorYear: 0, currentYear: 0, growth: 0.0 };
    averageTransactionValueDataPoint: DashboardDataPoint = { id: "", priorYear: 0, currentYear: 0, growth: 0.0 };
    averageRetailBasketTransactionValueDataPoint: DashboardDataPoint = { id: "", priorYear: 0, currentYear: 0, growth: 0.0 };
    lpPenetrationDataPoint: DashboardDataPoint = { id: "", priorYear: 0, currentYear: 0, growth: 0.0 };

    customerAnnualSales: AnnualSalesFigure[] = [{ id: "", month: 0, sales: 0.0 }];
    newCustomerAnnualSales: AnnualSalesFigure[] = [{ id: "", month: 0, sales: 0.0 }];
    returningCustomerAnnualSales: AnnualSalesFigure[] = [{ id: "", month: 0, sales: 0.0 }];
    eyeTestAnnualSales: AnnualSalesFigure[] = [{ id: "", month: 0, sales: 0.0 }];
    newEyeTestAnnualSales: AnnualSalesFigure[] = [{ id: "", month: 0, sales: 0.0 }];
    returningEyeTestAnnualSales: AnnualSalesFigure[] = [{ id: "", month: 0, sales: 0.0 }];
    salesAnnualSales: AnnualSalesFigure[] = [{ id: "", month: 0, sales: 0.0 }];
    turnoverAnnualSales: AnnualSalesFigure[] = [{ id: "", month: 0, sales: 0.0 }];
    transactionsAnnualSales: AnnualSalesFigure[] = [{ id: "", month: 0, sales: 0.0 }];
    retailBasketSalesAnnualSales: AnnualSalesFigure[] = [{ id: "", month: 0, sales: 0.0 }];
    retailBasketTransactionsAnnualSales: AnnualSalesFigure[] = [{ id: "", month: 0, sales: 0.0 }];
    averageTransactionValueAnnualSales: AnnualSalesFigure[] = [{ id: "", month: 0, sales: 0.0 }];
    averageRetailBasketTransactionValueAnnualSales: AnnualSalesFigure[] = [{ id: "", month: 0, sales: 0.0 }];
    lpPenetrationAnnualSales: AnnualSalesFigure[] = [{ id: "", month: 0, sales: 0.0 }];

    volumeGrowthAll: number = 0;
    volumeGrowthRetail: number = 0;
    valueGrowthAll: number = 0;
    valueGrowthRetail: number = 0;

    constructor(private storeService: StoreService, private settingService: SettingService) { }

    LoadDashboard() {

        this.ClearViews();

        this.GetGeneralSettings();

        this.GetCustomerCounts();
        this.GetEyeTestCounts();
        this.GetNewCustomerCounts();
        this.GetNewEyeTestCounts();
        this.GetRetailBasketSales();
        this.GetReturningCustomerCounts();
        this.GetReturningEyeTestCounts();
        this.GetSales();
        this.GetTurnovers();
        this.GetLPPenetration();

        this.showSales = true;
        this.showAllEyeTests = true;
        this.showAllCustomers = false;
        this.showToggle = true;
        this.dontShowToggle = false;
        this.showTransactions = true;
        this.showLp = false;
    }

    GetGeneralSettings() {

        this.settingService.loadSetting("Dashboard_ShowGPChart")
            .subscribe(s => {
                if (s) {
                    this.showGrossProfitChart = this.settingService.generalSetting.settingValue == "true" ? true : false;
                }
            });

    }

    ClearViews() {
        this.showAllCustomers = false;
        this.showAllEyeTests = false;
        this.showNewCustomers = false;
        this.showNewEyeTests = false;
        this.showReturningCustomers = false;
        this.showReturningEyeTests = false;
        this.showSales = false;
        this.showTurnover = false;
        this.showGrossProfitChart = false;
        this.showTransactions = false;
        this.showLp = false;
    }

    GetCustomerCounts() {

        this.storeService.loadCustomerCounts("All", this.group, this.store)
            .subscribe(s => {
                this.customerDataPoint = this.storeService.dashboardDataPoint;

                this.storeService.loadAnnualCustomerCounts("All", this.group, this.store)
                    .subscribe(x => {
                        this.customerAnnualSales = this.storeService.annualSalesFigures;
                    })

            });
    }

    GetNewCustomerCounts() {

        this.storeService.loadCustomerCounts("New", this.group, this.store)
            .subscribe(s => {
                this.newCustomerDataPoint = this.storeService.dashboardDataPoint;

                this.storeService.loadAnnualCustomerCounts("New", this.group, this.store)
                    .subscribe(x => {
                        this.newCustomerAnnualSales = this.storeService.annualSalesFigures;
                    })

            });

    }

    GetReturningCustomerCounts() {

        this.storeService.loadCustomerCounts("Returning", this.group, this.store)
            .subscribe(s => {
                this.returningCustomerDataPoint = this.storeService.dashboardDataPoint;

                this.storeService.loadAnnualCustomerCounts("Returning", this.group, this.store)
                    .subscribe(x => {
                        this.returningCustomerAnnualSales = this.storeService.annualSalesFigures;
                    })

            });

    }

    GetEyeTestCounts() {

        this.storeService.loadEyeTestCounts("All", this.group, this.store)
            .subscribe(s => {
                this.eyeTestDataPoint = this.storeService.dashboardDataPoint;
                this.eyeTests = this.eyeTestDataPoint.currentYear;

                this.storeService.loadAnnualEyeTestCounts("All", this.group, this.store)
                    .subscribe(x => {
                        this.eyeTestAnnualSales = this.storeService.annualSalesFigures;
                    })

            });
    }

    GetNewEyeTestCounts() {
        this.storeService.loadEyeTestCounts("New", this.group, this.store)
            .subscribe(s => {
                this.newEyeTestDataPoint = this.storeService.dashboardDataPoint;

                this.storeService.loadAnnualEyeTestCounts("New", this.group, this.store)
                    .subscribe(x => {
                        this.newEyeTestAnnualSales = this.storeService.annualSalesFigures;
                    })


            });
    }

    GetReturningEyeTestCounts() {
        this.storeService.loadEyeTestCounts("Returning", this.group, this.store)
            .subscribe(s => {
                this.returningEyeTestDataPoint = this.storeService.dashboardDataPoint;

                this.storeService.loadAnnualEyeTestCounts("Returning", this.group, this.store)
                    .subscribe(x => {
                        this.returningEyeTestAnnualSales = this.storeService.annualSalesFigures;
                    })


            });
    }

    GetTurnovers() {

        this.storeService.loadTurnover(this.group, this.store)
            .subscribe(s => {
                this.turnoverDataPoint = this.storeService.dashboardDataPoint;

                this.storeService.loadAnnualTurnover(this.group, this.store)
                    .subscribe(x => {
                        this.turnoverAnnualSales = this.storeService.annualSalesFigures;
                    })


            });
    }

    GetSales() {

        this.storeService.loadSales(this.group, this.store)
            .subscribe(s => {
                this.salesDataPoint = this.storeService.dashboardDataPoint;

                this.sales = this.salesDataPoint.currentYear;

                this.storeService.loadTransactionCounts(this.group, this.store)
                    .subscribe(s => {
                        this.transactionsDataPoint = this.storeService.dashboardDataPoint;

                        this.averageTransactionValueDataPoint.currentYear = this.salesDataPoint.currentYear / this.transactionsDataPoint.currentYear;
                        this.averageTransactionValueDataPoint.priorYear = this.salesDataPoint.priorYear / this.transactionsDataPoint.priorYear;
                        this.averageTransactionValueDataPoint.growth = Math.round(10000 * (this.averageTransactionValueDataPoint.currentYear / this.averageTransactionValueDataPoint.priorYear - 1)) / 100;

                        this.volumeGrowthAll = this.transactionsDataPoint.growth;
                        this.valueGrowthAll = this.averageTransactionValueDataPoint.growth;
                    });
            });


        this.storeService.loadAnnualSales(this.group, this.store)
            .subscribe(x => {
                this.salesAnnualSales = this.storeService.annualSalesFigures;

                this.storeService.loadAnnualTransactionCounts(this.group, this.store)
                    .subscribe(x => {
                        this.transactionsAnnualSales = this.storeService.annualSalesFigures;

                        this.averageTransactionValueAnnualSales = [];
                        this.averageTransactionValueAnnualSales.push({ id: "", month: this.salesAnnualSales[0].month, sales: Math.round(100 * (this.salesAnnualSales[0].sales / this.transactionsAnnualSales[0].sales)) / 100 });
                        this.averageTransactionValueAnnualSales.push({ id: "", month: this.salesAnnualSales[1].month, sales: Math.round(100 * (this.salesAnnualSales[1].sales / this.transactionsAnnualSales[1].sales)) / 100 });
                        this.averageTransactionValueAnnualSales.push({ id: "", month: this.salesAnnualSales[2].month, sales: Math.round(100 * (this.salesAnnualSales[2].sales / this.transactionsAnnualSales[2].sales)) / 100 });
                        this.averageTransactionValueAnnualSales.push({ id: "", month: this.salesAnnualSales[3].month, sales: Math.round(100 * (this.salesAnnualSales[3].sales / this.transactionsAnnualSales[3].sales)) / 100 });
                        this.averageTransactionValueAnnualSales.push({ id: "", month: this.salesAnnualSales[4].month, sales: Math.round(100 * (this.salesAnnualSales[4].sales / this.transactionsAnnualSales[4].sales)) / 100 });
                        this.averageTransactionValueAnnualSales.push({ id: "", month: this.salesAnnualSales[5].month, sales: Math.round(100 * (this.salesAnnualSales[5].sales / this.transactionsAnnualSales[5].sales)) / 100 });
                        this.averageTransactionValueAnnualSales.push({ id: "", month: this.salesAnnualSales[6].month, sales: Math.round(100 * (this.salesAnnualSales[6].sales / this.transactionsAnnualSales[6].sales)) / 100 });
                        this.averageTransactionValueAnnualSales.push({ id: "", month: this.salesAnnualSales[7].month, sales: Math.round(100 * (this.salesAnnualSales[7].sales / this.transactionsAnnualSales[7].sales)) / 100 });
                        this.averageTransactionValueAnnualSales.push({ id: "", month: this.salesAnnualSales[8].month, sales: Math.round(100 * (this.salesAnnualSales[8].sales / this.transactionsAnnualSales[8].sales)) / 100 });
                        this.averageTransactionValueAnnualSales.push({ id: "", month: this.salesAnnualSales[9].month, sales: Math.round(100 * (this.salesAnnualSales[9].sales / this.transactionsAnnualSales[9].sales)) / 100 });
                        this.averageTransactionValueAnnualSales.push({ id: "", month: this.salesAnnualSales[10].month, sales: Math.round(100 * (this.salesAnnualSales[10].sales / this.transactionsAnnualSales[10].sales)) / 100 });
                        this.averageTransactionValueAnnualSales.push({ id: "", month: this.salesAnnualSales[11].month, sales: Math.round(100 * (this.salesAnnualSales[11].sales / this.transactionsAnnualSales[11].sales)) / 100 });
                    })
            })

    }

    GetRetailBasketSales() {

        this.storeService.loadRetailBasketSales(this.group, this.store)
            .subscribe(s => {
                this.retailBasketSalesDataPoint = this.storeService.dashboardDataPoint;

                this.storeService.loadRetailBasketTransactionCounts(this.group, this.store)
                    .subscribe(s => {
                        this.retailBasketTransactionsDataPoint = this.storeService.dashboardDataPoint;

                        this.averageRetailBasketTransactionValueDataPoint.currentYear = this.retailBasketSalesDataPoint.currentYear / this.retailBasketTransactionsDataPoint.currentYear;
                        this.averageRetailBasketTransactionValueDataPoint.priorYear = this.retailBasketSalesDataPoint.priorYear / this.retailBasketTransactionsDataPoint.priorYear;
                        this.averageRetailBasketTransactionValueDataPoint.growth = Math.round(10000 * (this.averageRetailBasketTransactionValueDataPoint.currentYear / this.averageRetailBasketTransactionValueDataPoint.priorYear - 1)) / 100;

                        this.volumeGrowthRetail = this.retailBasketTransactionsDataPoint.growth;
                        this.valueGrowthRetail = this.averageRetailBasketTransactionValueDataPoint.growth;
                    });
            });

        this.storeService.loadAnnualRetailBasketSales(this.group, this.store)
            .subscribe(x => {
                this.retailBasketSalesAnnualSales = this.storeService.annualSalesFigures;

                this.storeService.loadAnnualRetailBasketTransactionCounts(this.group, this.store)
                    .subscribe(x => {
                        this.retailBasketTransactionsAnnualSales = this.storeService.annualSalesFigures;

                        this.averageRetailBasketTransactionValueAnnualSales = [];
                        this.averageRetailBasketTransactionValueAnnualSales.push({ id: "", month: this.retailBasketSalesAnnualSales[0].month, sales: this.retailBasketSalesAnnualSales[0].sales / this.retailBasketTransactionsAnnualSales[0].sales });
                        this.averageRetailBasketTransactionValueAnnualSales.push({ id: "", month: this.retailBasketSalesAnnualSales[1].month, sales: this.retailBasketSalesAnnualSales[1].sales / this.retailBasketTransactionsAnnualSales[1].sales });
                        this.averageRetailBasketTransactionValueAnnualSales.push({ id: "", month: this.retailBasketSalesAnnualSales[2].month, sales: this.retailBasketSalesAnnualSales[2].sales / this.retailBasketTransactionsAnnualSales[2].sales });
                        this.averageRetailBasketTransactionValueAnnualSales.push({ id: "", month: this.retailBasketSalesAnnualSales[3].month, sales: this.retailBasketSalesAnnualSales[3].sales / this.retailBasketTransactionsAnnualSales[3].sales });
                        this.averageRetailBasketTransactionValueAnnualSales.push({ id: "", month: this.retailBasketSalesAnnualSales[4].month, sales: this.retailBasketSalesAnnualSales[4].sales / this.retailBasketTransactionsAnnualSales[4].sales });
                        this.averageRetailBasketTransactionValueAnnualSales.push({ id: "", month: this.retailBasketSalesAnnualSales[5].month, sales: this.retailBasketSalesAnnualSales[5].sales / this.retailBasketTransactionsAnnualSales[5].sales });
                        this.averageRetailBasketTransactionValueAnnualSales.push({ id: "", month: this.retailBasketSalesAnnualSales[6].month, sales: this.retailBasketSalesAnnualSales[6].sales / this.retailBasketTransactionsAnnualSales[6].sales });
                        this.averageRetailBasketTransactionValueAnnualSales.push({ id: "", month: this.retailBasketSalesAnnualSales[7].month, sales: this.retailBasketSalesAnnualSales[7].sales / this.retailBasketTransactionsAnnualSales[7].sales });
                        this.averageRetailBasketTransactionValueAnnualSales.push({ id: "", month: this.retailBasketSalesAnnualSales[8].month, sales: this.retailBasketSalesAnnualSales[8].sales / this.retailBasketTransactionsAnnualSales[8].sales });
                        this.averageRetailBasketTransactionValueAnnualSales.push({ id: "", month: this.retailBasketSalesAnnualSales[9].month, sales: this.retailBasketSalesAnnualSales[9].sales / this.retailBasketTransactionsAnnualSales[9].sales });
                        this.averageRetailBasketTransactionValueAnnualSales.push({ id: "", month: this.retailBasketSalesAnnualSales[10].month, sales: this.retailBasketSalesAnnualSales[10].sales / this.retailBasketTransactionsAnnualSales[10].sales });
                        this.averageRetailBasketTransactionValueAnnualSales.push({ id: "", month: this.retailBasketSalesAnnualSales[11].month, sales: this.retailBasketSalesAnnualSales[11].sales / this.retailBasketTransactionsAnnualSales[11].sales });
                    })
            })

    }
    GetLPPenetration() {
        this.storeService.loadLPPenetrationYearly(this.storeId).subscribe(s => {
            this.lpPenetrationAnnualSales = [];
            for (var i = 0; i < this.storeService.lpPenetration.length; i++) {
                this.lpPenetrationAnnualSales.push({ id: "", month: this.storeService.lpPenetration[i].yearMonth, sales: Math.round((this.storeService.lpPenetration[i].lensPortalOrders / this.storeService.lpPenetration[i].total) * 10000.0) / 100.0});
            }
        })
        this.storeService.loadMainPenetrationStat(this.storeId).subscribe(s => {
            var total = 0;
            var lensPortalOrders = 0;
            for (var i = 0; i < this.storeService.lpPenetrationMainStat.length; i++) {
                total = total + this.storeService.lpPenetrationMainStat[i].total;
                lensPortalOrders = lensPortalOrders + this.storeService.lpPenetrationMainStat[i].lensPortalOrders;
            }
            this.lpPenetrationDataPoint.currentYear = lensPortalOrders;
            this.lpPenetrationDataPoint.growth = Math.round((lensPortalOrders / total) * 10000.0) / 100.0;
        })
    }

    onShowTurnover(show: boolean) {
        this.showSales = false;
        this.showTurnover = show;
    }

    onShowSales(show: boolean) {
        this.showTurnover = false;
        this.showSales = show;
    }

    onShowNewCustomers(show: boolean) {
        this.showReturningCustomers = false;
        this.showAllCustomers = false;
        this.showTransactions = false;
        this.showNewCustomers = show;
    }

    onShowReturningCustomers(show: boolean) {
        this.showAllCustomers = false;
        this.showNewCustomers = false;
        this.showTransactions = false;
        this.showReturningCustomers = show;
    }

    onShowCustomers(show: boolean) {
        this.showReturningCustomers = false;
        this.showNewCustomers = false;
        this.showTransactions = false;
        this.showAllCustomers = show;
    }

    onShowNewEyeTests(show: boolean) {
        this.showReturningEyeTests = false;
        this.showAllEyeTests = false;
        this.showNewEyeTests = show;
    }

    onShowReturningEyeTests(show: boolean) {
        this.showNewEyeTests = false;
        this.showAllEyeTests = false;
        this.showReturningEyeTests = show;
    }

    onShowEyeTests(show: boolean) {
        this.showReturningEyeTests = false;
        this.showNewEyeTests = false;
        this.showAllEyeTests = show;
    }

    onUpdateSubtitle(subtitle: string) {
        this.subtitle = subtitle;
    }

    onShowTransactions(show: boolean) {
        this.showReturningCustomers = false;
        this.showNewCustomers = false;
        this.showAllCustomers = false;
        this.showTransactions = show;
    }

    //onShowLp(show: boolean) {
    //    this.showTransactions = false;
    //    this.showLp = show;
    //}

    //onSelectStore(store: Store) {
    //    if (store.id > 0) {
    //        this.store = store;
    //        this.LoadDashboard();
    //    }
    //    else {
    //        this.LoadDashboard();
    //    }
    //}

    //onSelectGroup(group: Group) {
    //    if (group.id > 0) {
    //        this.group = group;
    //        this.LoadDashboard();
    //    }
    //    else {
    //        this.LoadDashboard();
    //    }
    //}
    
}