import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../interfaces/user';

@Injectable()
export class UserService {
    
    public user: User;
    public users: User[] = [];

    constructor(private http: HttpClient) { }

    public register(user: User): Observable<boolean> {
        const url = '/api/users/v1';

        return this.http
            .post(url, user)
            .pipe(map((data: any) => {
                return true;
            }));

    }

}
