import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartControlsService {

  showData = false;
  fullScreen = false;
  constructor() { }
}
