import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
//import { SSL_OP_NO_SESSION_RESUMPTION_ON_RENEGOTIATION } from 'constants';
import { Observable } from 'rxjs';
import { API_URL } from './apiUrl';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(@Inject(API_URL) private apiUrl: string) { }

  intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

    const token = localStorage.getItem('token');

    if (token) {
        const cloned = req.clone({
            url: this.prepareUrl(req.url),
            setHeaders: { Authorization: 'Bearer ' + token }
            });

            return next.handle(cloned);
    }
    else {
        const cloned = req.clone({
            url: this.prepareUrl(req.url)
        });

        return next.handle(cloned);
    }
  }

    private isAbsoluteUrl(url: string): boolean {
        const absolutePattern = /^http?:\/\//i;
        return absolutePattern.test(url);
    }

    private prepareUrl(url: string): string {
        url = this.isAbsoluteUrl(url) ? url : this.apiUrl + '/' + url;
        return url.replace(/([^:]\/)\/+/g, '$1');
    }

}
