import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { StoreService } from '../../../../shared/services/storeService';
import { ChartData } from '../../../../shared/interfaces/chartData';
import { Group } from '../../../../shared/interfaces/group';
import { Store } from '../../../../shared/interfaces/store';


@Component({
    selector: 'sales-run-rate',
    templateUrl: 'salesRunRate.component.html'
})
export class SalesRunRateComponent implements OnInit {

    @Output() subtitleEmitter = new EventEmitter<string>();

    title: string = "";
    subtitle: string = "";

    @Input() store: Store;
    @Input() group: string;

    private _sales: number;
    @Input() set sales(value: number) {
        this._sales = value;
        //this.UpdateSalesChart();
    }
    get sales(): number {
        return this._sales;
    }

    public chartData: ChartData;
    public lineChartMax: number;
    public lineChartData: Array<any>;
    public lineChartLabels: Array<any>;

    private salesModel: string = "Sales";

    constructor(private storeService: StoreService) { }

    ngOnInit(): void {

        this.lineChartLabels = [1,2,3];

        this.lineChartData = [
            {
                data: [1,2,3],
                label: '123'
            },
            {
                data: [1,2,3],
                label: '123'
            }];

        this.updateSalesChart();
    }

    updateSalesChart() {


        this.storeService.loadStoreSalesRunRate(this.salesModel, this.group, this.store)
            .subscribe(s => {

                this.chartData = this.storeService.chartData;

                this.title = this.chartData.title;
                this.subtitle = this.chartData.subtitle;

                this.subtitleEmitter.emit(this.subtitle);

                this.lineChartLabels = this.chartData.measures[0].salesFigures.map(s => s.day);
                this.lineChartMax = this.chartData.maxMeasure;

                this.lineChartData = [
                    {
                        data: this.chartData.measures[0].salesFigures.map(s => s.sales),
                        label: this.chartData.measures[0].title
                    },
                    {
                        data: this.chartData.measures[1].salesFigures.map(s => s.sales),
                        label: this.chartData.measures[1].title
                    }];
            });

    }

    onDownloadData() {

        var csvData = this.getChartData();
        var a = document.createElement("a");
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        var blob = new Blob([csvData], { type: 'text/csv' });
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        var date: Date = new Date();
        var strData: string = (date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate()).toString();
        var link: string = this.salesModel + "_" + strData + '.csv';
        a.download = link.toLocaleLowerCase();
        a.click();

    }


    getChartData() {

        var str = '';
        var row = "";

        str = 'Day,' + this.chartData.measures[0].title + ',' + this.chartData.measures[1].title + '\r\n'

        for (var i = 0; i < this.lineChartLabels.length; i++) {
            str += this.lineChartLabels[i] + ',' + this.lineChartData[0].data[i] + ',' + this.lineChartData[1].data[i] + '\r\n';
        }

        return str;
    }

}