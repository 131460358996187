import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from 'rxjs/operators';
import { Observable } from "rxjs/internal/Observable";
import { GeneralSetting } from "../interfaces/generalSetting";

@Injectable()
export class SettingService {

    generalSetting: GeneralSetting;

    constructor(private http: HttpClient) { }

    public loadSetting(settingName: string): Observable<boolean> {

        return this.http.get("/api/Settings/v1/" + settingName)
            .pipe(map((data: any) => {
                this.generalSetting = data;
                return (this.generalSetting != null);
            }));
    }

}