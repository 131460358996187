import { Component, OnInit, Input } from '@angular/core';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';


@Component({
    selector: 'combo-chart',
    templateUrl: 'comboChart.component.html'
})
export class ComboChartComponent implements OnInit {


    // comboChart

    public comboChartElements = 27;

    @Input() public comboChartMax: number;
    @Input() public comboChartData: Array<any>;
    @Input() public comboChartLabels: Array<any>;

    public comboChartOptions: any;

    ngOnInit(): void {

        this.comboChartOptions = {
            tooltips: {
                enabled: false,
                custom: CustomTooltips,
                intersect: true,
                mode: 'index',
                position: 'nearest',
                callbacks: {
                    labelColor: function (tooltipItem, chart) {
                        return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor };
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    gridLines: {
                        drawOnChartArea: false,
                    },
                    ticks: {
                        callback: function (value: any) {
                            return value;
                        }
                    }
                }],
                yAxes: [{
                    id: 'yAxis1',
                    title:'Title',
                    position: 'left',
                    ticks: {
                        beginAtZero: false,
                        maxTicksLimit: 10//,
                        //stepSize: Math.ceil(this.comboChartMax / 10),
                        //max: this.comboChartMax
                    }
                }, {
                    id: 'yAxis2',
                    title:'Title',
                    position: 'right',
                    gridLines: {
                        drawOnChartArea: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 10,
                        //stepSize: Math.ceil(this.comboChartMax / 10),
                        max: 100
                    }
                }]
            },
            elements: {
                line: {
                    borderWidth: 2
                },
                point: {
                    radius: 3,
                    hitRadius: 10,
                    hoverRadius: 4,
                    hoverBorderWidth: 3,
                }
            },
            legend: {
                display: true
            }
        };
    }

    public mainChartColours: Array<any> = [
        { // brandInfo
            backgroundColor: hexToRgba(getStyle('--info'), 10),
            borderColor: getStyle('--info'),
            pointHoverBackgroundColor: '#fff'
        },
        { // brandSuccess
            backgroundColor: 'transparent',
            borderColor: getStyle('--success'),
            pointHoverBackgroundColor: '#fff'
        },
        { // brandDanger
            backgroundColor: 'transparent',
            borderColor: getStyle('--danger'),
            pointHoverBackgroundColor: '#fff',
            borderWidth: 1,
            borderDash: [8, 5]
        }
    ];

    public comboChartLegend = true;
    public comboChartType = 'bar';


}