import { Component, OnInit, Input } from '@angular/core';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { StoreService } from '../../../../shared/services/storeService';
import { CategorySales } from '../../../../shared/interfaces/categorySales';
import { Group } from '../../../../shared/interfaces/group';
import { Store } from '../../../../shared/interfaces/store';


@Component({
    selector: 'medical-aid',
    templateUrl: 'medicalAid.component.html'
})
export class MedicalAidComponent implements OnInit {

    @Input() store: Store;
    @Input() group: string;

    private _sales: number;
    @Input() set sales(value: number) {
        this._sales = value;
        //this.UpdateMedicalAidChart();
    }
    get sales(): number {
        return this._sales;
    }

    privateMAChartData: CategorySales[] = [];
    privateMAColour: string;
    maChartData: CategorySales[] = [];
    maColour: string;

    constructor(private storeService: StoreService) { }

    ngOnInit(): void {

        this.UpdateMedicalAidChart();

        //this.privateMAChartData = [{ id: "", category: "Medical Aid", proportion: 25, sales: 1223452 }, { id: "", category: "Private", proportion: 37, sales: 123441 }]
        //this.privateMAColour = "bg-warning";

        //this.maChartData = [{ id: "", category: "Discovery", proportion: 79, sales: 1223452 }, { id: "", category: "Bonitas", proportion: 50, sales: 123441 }, { id: "", category: "Gems", proportion: 45, sales: 123441 }, { id: "", category: "Fedhealth", proportion: 30, sales: 123441 }, { id: "", category: "Other", proportion: 12, sales: 123441 }]
        //this.maColour = "bg-success";
    }

    UpdateMedicalAidChart() {

        this.storeService.loadMedicalAidSales(this.group, this.store)
            .subscribe(s => {
                if (s) {
                    this.maChartData = this.storeService.categorySales;
                    this.maColour = "bg-success";
                }
            });

        this.storeService.loadMedicalAidVsPrivateSales(this.group, this.store)
            .subscribe(s => {
                if (s) {
                    this.privateMAChartData = this.storeService.categorySales;
                    this.privateMAColour = "bg-warning";
                }
            });

    }



}