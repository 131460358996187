import { UrlSerializer, UrlTree, DefaultUrlSerializer } from '@angular/router';

export class CustomUrlSerializer implements UrlSerializer {
    parse(url: any): UrlTree {
        let dus = new DefaultUrlSerializer();
        return dus.parse(url);
    }
    serialize(tree: UrlTree): any {
        let dus = new DefaultUrlSerializer();
        const path = dus.serialize(tree);

        const splitOn = path.indexOf('?') > -1 ? '?' : '#';
        const pathArray = path.split(splitOn);
        pathArray[0] += '/';

        if (pathArray[0] === '//') {
            pathArray[0] = '/';
        }

        return pathArray.join(splitOn);
    }
}