import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { User } from '../shared/interfaces/user';
import { StorageService } from '../shared/services/storageService';
import { Login } from '../shared/interfaces/login';
import { Role } from '../shared/interfaces/role';

@Injectable()
export class AuthService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private storage: StorageService
  ) { }

  private loggedIn = new BehaviorSubject<boolean>(false);
  private loggedOut = new BehaviorSubject<boolean>(false);
  public isAdmin: boolean;
  public isManager: boolean;
  public isMarketShareUser: boolean;
  public isOutboundAgent: boolean;
  public roles: Role[];
  public user: User;

  get isLoggedIn() {

    const token = localStorage.getItem('token');

    if (token == null) {
      this.loggedIn.next(false);
    }
    else {
      if (moment().isBefore(this.getExpiration())) {
        this.loggedIn.next(true);
      }
    }

    return this.loggedIn.asObservable();
  }

  get isLoggedOut() {

    const token = localStorage.getItem('token');

    if (token == null) {
      this.loggedOut.next(true);
    }
    else {
      if (moment().isBefore(this.getExpiration())) {
        this.loggedOut.next(false);
      }
    }

    return this.loggedOut.asObservable();

  }

  get isMarketShareUserLoggedIn() {

    return this.http
      .get("/api/users/v1/IsMarketShareUser")
      .pipe(map((data: any) => {
        this.isMarketShareUser = data;
        return this.isMarketShareUser;
      }));

  }

  get isManagerLoggedIn() {

    return this.http
      .get("/api/users/isManager")
      .pipe(map((data: any) => {
        this.isManager = data;
        return this.isManager;
      }));

  }

  public isUserManager() {

    return this.http
      .get("/api/users/IsManager")
      .pipe(map((data: any) => {
        this.isManager = data;
        return this.isManager;
      }));

  }

  get isAdminLoggedIn() {

    return this.http
      .get("/api/users/isAdmin")
      .pipe(map((data: any) => {
        this.isAdmin = data;
        return this.isAdmin;
      }));

  }

    login(login: Login): Observable<boolean> {
        return this.http
            .post("/Account/Login", login)
            .pipe(map((data: any) => {

                if (!(data.result.token.length == 0) || data.result.expiration > new Date()) {
                this.storage.store('token', data.result.token);
                this.storage.store('tokenExpiration', JSON.stringify(data.result.expiration.valueOf()));
                //this.storage.store('tokenExpiration','2021-08-12');

                return true;
            }
        }
      ));
  }

  logout() {
    this.http.post("/account/logout", {}).subscribe();
    this.storage.clear('token');
    this.storage.clear('tokenExpiration');
    this.loggedIn.next(false);
    this.router.navigate(["login"]);
  }

  getExpiration() {
    const expiration = localStorage.getItem("tokenExpiration");
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  getRoles(): Observable<boolean> {

    return this.http.get("/api/users/v1/GetUserRoles/")
      .pipe(map((data: any[]) => {
        this.roles = data;
        return this.roles != null;
      }));
  }

  getUser(): Observable<boolean> {

    return this.http.get("/api/users/v1/")
      .pipe(map((data: any) => {
        this.user = data;
        return this.user != null;
      }));
  }


}
