import { Component, OnInit, Input } from '@angular/core';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';


@Component({
    selector: 'line-chart',
    templateUrl: 'lineChart.component.html'
})
export class LineChartComponent  implements OnInit {
    

    // lineChart

    public lineChartElements = 27;

    @Input() public lineChartMax: number;
    @Input() public lineChartData: Array<any>;
    @Input() public lineChartLabels: Array<any>;

    public lineChartOptions: any;

    ngOnInit(): void {

        this.lineChartOptions = {
            tooltips: {
                enabled: false,
                custom: CustomTooltips,
                intersect: true,
                mode: 'index',
                position: 'nearest',
                callbacks: {
                    labelColor: function (tooltipItem, chart) {
                        return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor };
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    gridLines: {
                        drawOnChartArea: false,
                    },
                    ticks: {
                        callback: function (value: any) {
                            return value;
                        }
                    }
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: false,
                        maxTicksLimit: 10,
                        stepSize: Math.ceil(this.lineChartMax / 10),
                        max: this.lineChartMax
                    }
                }]
            },
            elements: {
                line: {
                    borderWidth: 2
                },
                point: {
                    radius: 0,
                    hitRadius: 10,
                    hoverRadius: 4,
                    hoverBorderWidth: 3,
                }
            },
            legend: {
                display: true
            }
        };
    }

    public mainChartColours: Array<any> = [
        { // brandInfo
            backgroundColor: hexToRgba(getStyle('--info'), 10),
            borderColor: getStyle('--info'),
            pointHoverBackgroundColor: '#fff'
        },
        { // brandSuccess
            backgroundColor: 'transparent',
            borderColor: getStyle('--success'),
            pointHoverBackgroundColor: '#fff'
        },
        { // brandDanger
            backgroundColor: 'transparent',
            borderColor: getStyle('--danger'),
            pointHoverBackgroundColor: '#fff',
            borderWidth: 1,
            borderDash: [8, 5]
        }
    ];

    public lineChartLegend = true;
    public lineChartType = 'line';

    
}