import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { DashboardDataPoint } from '../../../shared/interfaces/dashboardDataPoint';
import { AnnualSalesFigure } from '../../../shared/interfaces/annualSalesFigure';

@Component({
    selector: 'widget',
    templateUrl: 'widget.component.html',
    styleUrls: ['widget.component.css']
})
export class WidgetComponent implements OnInit {

    @Output() toggleEmitter = new EventEmitter<boolean>();

    ngOnInit(): void {
        this.LoadChartDetails();
    }

    LoadChartDetails() {

        this.chartData = [{ data: this.annualSalesFigures.map(a => a.sales), label:this.title }]
        this.chartLabels = this.annualSalesFigures.map(a => a.month);

    }

    private _growth: number;
    @Input() set growth(value: number) {
        this._growth = value;

        if (this._growth <= 0)
            this.colour = "bg-danger";
        else if (this._growth < 8)
            this.colour = "bg-warning";
        else
            this.colour = "bg-success";
    }

    get growth(): number {
        return this._growth;
    }

    private _currentYear: number;
    @Input() set currentYear(value: number) {
        this._currentYear = value;
    }

    get currentYear(): number {
        return this._currentYear;
    }

    private colour: string = "bg-info";

    @Input() public showToggle: boolean;
    @Input() public title: string = "";
    //@Input() public dashboardDataPoint: DashboardDataPoint = { id: "", priorYear: 0, currentYear: 0, growth: 0.0 };

    private _annualSalesFigures: AnnualSalesFigure[];

    @Input() set annualSalesFigures(value: AnnualSalesFigure[]) {
        this._annualSalesFigures = value;
        this.LoadChartDetails();
    }

    get annualSalesFigures(): AnnualSalesFigure[] {
        return this._annualSalesFigures;
    }

    toggleMetric() {
        this.toggleEmitter.emit(true);
    }

    public chartData: Array<any> = [
        {
            data: [78, 81, 80, 45, 34, 12, 40],
            label: 'Series A'
        }
    ];
    public chartMax: number = 80;
    public chartLabels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    //// lineChart1
    //public lineChart1Data: Array<any> = [
    //    {
    //        data: [65, 59, 84, 84, 51, 55, 40],
    //        label: 'Series A'
    //    }
    //];
    //public lineChart1Labels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    //public lineChart1Options: any = {
    //    tooltips: {
    //        enabled: false,
    //        custom: CustomTooltips
    //    },
    //    maintainAspectRatio: false,
    //    scales: {
    //        xAxes: [{
    //            gridLines: {
    //                color: 'transparent',
    //                zeroLineColor: 'transparent'
    //            },
    //            ticks: {
    //                fontSize: 2,
    //                fontColor: 'transparent',
    //            }

    //        }],
    //        yAxes: [{
    //            display: false,
    //            ticks: {
    //                display: false,
    //                min: 40 - 5,
    //                max: 84 + 5,
    //            }
    //        }],
    //    },
    //    elements: {
    //        line: {
    //            borderWidth: 1
    //        },
    //        point: {
    //            radius: 4,
    //            hitRadius: 10,
    //            hoverRadius: 4,
    //        },
    //    },
    //    legend: {
    //        display: false
    //    }
    //};
    //public lineChart1Colours: Array<any> = [
    //    {
    //        backgroundColor: getStyle('--primary'),
    //        borderColor: 'rgba(255,255,255,.55)'
    //    }
    //];
    //public lineChart1Legend = false;
    //public lineChart1Type = 'line';

    //// lineChart2
    //public lineChart2Data: Array<any> = [
    //    {
    //        data: [1, 18, 9, 17, 34, 22, 11],
    //        label: 'Series A'
    //    }
    //];
    //public lineChart2Labels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    //public lineChart2Options: any = {
    //    tooltips: {
    //        enabled: false,
    //        custom: CustomTooltips
    //    },
    //    maintainAspectRatio: false,
    //    scales: {
    //        xAxes: [{
    //            gridLines: {
    //                color: 'transparent',
    //                zeroLineColor: 'transparent'
    //            },
    //            ticks: {
    //                fontSize: 2,
    //                fontColor: 'transparent',
    //            }

    //        }],
    //        yAxes: [{
    //            display: false,
    //            ticks: {
    //                display: false,
    //                min: 1 - 5,
    //                max: 34 + 5,
    //            }
    //        }],
    //    },
    //    elements: {
    //        line: {
    //            tension: 0.00001,
    //            borderWidth: 1
    //        },
    //        point: {
    //            radius: 4,
    //            hitRadius: 10,
    //            hoverRadius: 4,
    //        },
    //    },
    //    legend: {
    //        display: false
    //    }
    //};
    //public lineChart2Colours: Array<any> = [
    //    { // grey
    //        backgroundColor: getStyle('--info'),
    //        borderColor: 'rgba(255,255,255,.55)'
    //    }
    //];
    //public lineChart2Legend = false;
    //public lineChart2Type = 'line';


    // lineChart3
    
    //public lineChart3Options: any = {
    //    tooltips: {
    //        enabled: false,
    //        custom: CustomTooltips
    //    },
    //    maintainAspectRatio: false,
    //    scales: {
    //        xAxes: [{
    //            display: false
    //        }],
    //        yAxes: [{
    //            display: false
    //        }]
    //    },
    //    elements: {
    //        line: {
    //            borderWidth: 2
    //        },
    //        point: {
    //            radius: 0,
    //            hitRadius: 10,
    //            hoverRadius: 4,
    //        },
    //    },
    //    legend: {
    //        display: false
    //    }
    //};
    //public lineChart3Colours: Array<any> = [
    //    {
    //        backgroundColor: 'rgba(255,255,255,.2)',
    //        borderColor: 'rgba(255,255,255,.55)',
    //    }
    //];
    //public lineChart3Legend = false;
    //public lineChart3Type = 'line';


    //// barChart1
    //public barChart1Data: Array<any> = [
    //    {
    //        data: [78, 81, 80, 45, 34, 12, 40, 78, 81, 80, 45, 34, 12, 40, 12, 40],
    //        label: 'Series A',
    //        barPercentage: 0.6,
    //    }
    //];
    //public barChart1Labels: Array<any> = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16'];
    //public barChart1Options: any = {
    //    tooltips: {
    //        enabled: false,
    //        custom: CustomTooltips
    //    },
    //    maintainAspectRatio: false,
    //    scales: {
    //        xAxes: [{
    //            display: false,
    //        }],
    //        yAxes: [{
    //            display: false
    //        }]
    //    },
    //    legend: {
    //        display: false
    //    }
    //};
    //public barChart1Colours: Array<any> = [
    //    {
    //        backgroundColor: 'rgba(255,255,255,.3)',
    //        borderWidth: 0
    //    }
    //];
    //public barChart1Legend = false;
    //public barChart1Type = 'bar';

}