import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExternalStore } from '../interfaces/externalStore';
import { MarketShare } from '../interfaces/marketShare';
import { StoreDayEnd } from '../interfaces/storeDayEnd';
import { ChartData } from '../interfaces/chartData';
import { DashboardDataPoint } from '../interfaces/dashboardDataPoint';
import { AnnualSalesFigure } from '../interfaces/annualSalesFigure';
import { CategorySales } from '../interfaces/categorySales';
import { Store } from '../interfaces/store';
import { Group } from '../interfaces/group';
import { StoreEntity } from '../interfaces/storeEntity';
import { StoreServer } from '../interfaces/storeServer';
import { GrossProfit } from '../interfaces/grossProfit';
import { LicenceFee } from '../interfaces/licenceFee';
import { LPPenetrationYearly } from '../interfaces/lpPenetrationYearly';

@Injectable()
export class StoreService {

    stores: ExternalStore[];
    internalStores: Store[];
    store: Store;
    storeEntity: StoreEntity;
    storeServer: StoreServer;
    groups: Group[];
    group: Group;
    marketShare: MarketShare[];
    storeDayEnds: StoreDayEnd[];
    chartData: ChartData;
    dashboardDataPoint: DashboardDataPoint;
    annualSalesFigures: AnnualSalesFigure[];
    categorySales: CategorySales[];
    grossProfits: GrossProfit[];
    licenceFees: LicenceFee[];
    lpPenetration: LPPenetrationYearly[];
    lpPenetrationMainStat: LPPenetrationYearly[];
    storeId: string;

    constructor(private http: HttpClient) { }

    public loadStores(groupName: string): Observable<boolean> {

        return this.http.get("/api/Dashboard/v1/Stores/" + groupName)
            .pipe(map((data: any[]) => {
                this.internalStores = data;
                return (this.internalStores != null);
            }));
    }

    public loadGroups(): Observable<boolean> {

        return this.http.get("/api/Dashboard/v1/Groups")
            .pipe(map((data: any[]) => {
                this.groups = data;
                return (this.groups != null);
            }));
    }

    public loadGroup(groupName: string): Observable<boolean> {

        return this.http.get("/api/Dashboard/v1/Group/" + groupName)
            .pipe(map((data: any) => {
                this.group = data;
                return (this.group != null);
            }));
    }

    public loadStore(storeId: string): Observable<boolean> {

        return this.http.get("/api/Dashboard/v1/Store/" + storeId)
            .pipe(map((data: any) => {
                this.store = data;
                return (this.store != null);
            }));
    }

    public loadStoreByBranchCode(branchCode: string): Observable<boolean> {

        return this.http.get("/api/Stores/v1/StoreServer/" + branchCode)
            .pipe(map((data: any) => {
                this.storeServer = data;
                return (this.storeServer != null);
            }));

    }

    public loadMarketShareStores(): Observable<boolean> {

        return this.http.get("/api/MarketShare/v1/Stores")
        .pipe(map((data: any[]) => {
        this.stores = data;
        return (this.stores != null);
        }));
    }

    public loadMarketShare(dateFrom: Date, dateTo: Date): Observable<boolean> {

    return this.http.get("/api/MarketShare/v1/" + dateFrom.toJSON() + "/" + dateTo.toJSON())
        .pipe(map((data: any[]) => {
        this.marketShare = data;
            return (this.marketShare != null);
        }));
    }

    public loadStoreDayEnds(): Observable<boolean> {

        return this.http.get("/api/StoreDayEnds/v1/")
            .pipe(map((data: any[]) => {
                this.storeDayEnds = data;
                return (this.storeDayEnds != null);
            }));

    }

    public LoadStoreLicenceFees(): Observable<boolean> {

        return this.http.get("/MonthEnds/api/v1/LicenseFees")
            .pipe(map((data: any[]) => {
                this.licenceFees = data;
                return (this.licenceFees != null);
            }));

    }

    public loadStoreSalesRunRate(salesModel: string, group: string, store: Store): Observable<boolean> {

        var url = "/api/Dashboard/v1/SalesRunRate/" + salesModel;

        if (group) {
            url += "?group=" + group;

            if (store)
                url += "&storeId=" + store.id;
        }

        return this.http.get(url)
            .pipe(map((data: any) => {
                this.chartData = data;
                return (this.chartData != null);
            }));

    }

    public loadStoreEyeTestRunRate(eyeTestType: string, group: Group, store: Store): Observable<boolean> {

        var url = "/api/Dashboard/v1/EyeTestRunRate/" + eyeTestType;

        if (group) {
            url += "?group=" + group;

            if (store)
                url += "&storeId=" + store.id;
        }

        return this.http.get(url)
            .pipe(map((data: any) => {
                this.chartData = data;
                return (this.chartData != null);
            }));

    }

    public loadAnnualCustomerCounts(customerType: string, group: string, store: Store): Observable<boolean> {

        var url = "/api/Dashboard/v1/AnnualCustomers/" + customerType;

        if (group) {
            url += "?group=" + group;

            if (store)
                url += "&storeId=" + store.id;
        }

        return this.http.get(url)
            .pipe(map((data: any[]) => {
                this.annualSalesFigures = data;
                return (this.annualSalesFigures != null);
            }));

    }

    public loadAnnualEyeTestCounts(customerType: string, group: string, store: Store): Observable<boolean> {

        var url = "/api/Dashboard/v1/AnnualEyeTests/" + customerType;

        if (group) {
            url += "?group=" + group;

            if (store)
                url += "&storeId=" + store.id;
        }

        return this.http.get(url)
            .pipe(map((data: any[]) => {
                this.annualSalesFigures = data;
                return (this.annualSalesFigures != null);
            }));

    }

    public loadAnnualSales(group: string, store: Store): Observable<boolean> {

        var url = "/api/Dashboard/v1/AnnualSales";

        if (group) {
            url += "?group=" + group;

            if (store)
                url += "&storeId=" + store.id;
        }

        return this.http.get(url)
            .pipe(map((data: any[]) => {
                this.annualSalesFigures = data;
                return (this.annualSalesFigures != null);
            }));

    }

    public loadAnnualTurnover(group: string, store: Store): Observable<boolean> {

        var url = "/api/Dashboard/v1/AnnualTurnover";

        if (group) {
            url += "?group=" + group;

            if (store)
                url += "&storeId=" + store.id;
        }

        return this.http.get(url)
            .pipe(map((data: any[]) => {
                this.annualSalesFigures = data;
                return (this.annualSalesFigures != null);
            }));

    }

    public loadAnnualTransactionCounts(group: string, store: Store): Observable<boolean> {

        var url = "/api/Dashboard/v1/AnnualTransactions";

        if (group) {
            url += "?group=" + group;

            if (store)
                url += "&storeId=" + store.id;
        }

        return this.http.get(url)
            .pipe(map((data: any[]) => {
                this.annualSalesFigures = data;
                return (this.annualSalesFigures != null);
            }));

    }

    public loadAnnualRetailBasketSales(group: string, store: Store): Observable<boolean> {

        var url = "/api/Dashboard/v1/AnnualRetailBasketSales";

        if (group) {
            url += "?group=" + group;

            if (store)
                url += "&storeId=" + store.id;
        }

        return this.http.get(url)
            .pipe(map((data: any[]) => {
                this.annualSalesFigures = data;
                return (this.annualSalesFigures != null);
            }));

    }

    public loadAnnualRetailBasketTransactionCounts(group: string, store: Store): Observable<boolean> {

        var url = "/api/Dashboard/v1/AnnualRetailBasketTransactions";

        if (group) {
            url += "?group=" + group;

            if (store)
                url += "&storeId=" + store.id;
        }

        return this.http.get(url)
            .pipe(map((data: any[]) => {
                this.annualSalesFigures = data;
                return (this.annualSalesFigures != null);
            }));

    }

    public loadCustomerCounts(customerType: string, group: string, store: Store): Observable<boolean> {

        var url = "/api/Dashboard/v1/Customers/" + customerType;

        if (group) {
            url += "?group=" + group;

            if (store)
                url += "&storeId=" + store.id;
        }

        return this.http.get(url)
        .pipe(map((data: any) => {
            this.dashboardDataPoint = data;
            return (this.dashboardDataPoint != null);
        }));

    }

    public loadEyeTestCounts(customerType: string, group: string, store: Store): Observable<boolean> {

        var url = "/api/Dashboard/v1/EyeTests/" + customerType;

        if (group) {
            url += "?group=" + group;

            if (store)
                url += "&storeId=" + store.id;
        }

        return this.http.get(url)
            .pipe(map((data: any) => {
                this.dashboardDataPoint = data;
                return (this.dashboardDataPoint != null);
            }));

    }

    public loadSales(group: string, store: Store): Observable<boolean> {

        var url = "/api/Dashboard/v1/Sales";

        if (group) {
            url += "?group=" + group;

            if (store)
                url += "&storeId=" + store.id;
        }

        return this.http.get(url)
            .pipe(map((data: any) => {
                this.dashboardDataPoint = data;
                return (this.dashboardDataPoint != null);
            }));

    }

    public loadTurnover(group: string, store: Store): Observable<boolean> {

        var url = "/api/Dashboard/v1/Turnover";

        if (group) {
            url += "?group=" + group;

            if (store)
                url += "&storeId=" + store.id;
        }

        return this.http.get(url)
            .pipe(map((data: any) => {
                this.dashboardDataPoint = data;
                return (this.dashboardDataPoint != null);
            }));

    }

    public loadTransactionCounts(group: string, store: Store): Observable<boolean> {

        var url = "/api/Dashboard/v1/Transactions";

        if (group) {
            url += "?group=" + group;

            if (store)
                url += "&storeId=" + store.id;
        }

        return this.http.get(url)
            .pipe(map((data: any) => {
                this.dashboardDataPoint = data;
                return (this.dashboardDataPoint != null);
            }));

    }

    public loadRetailBasketSales(group: string, store: Store): Observable<boolean> {

        var url = "/api/Dashboard/v1/RetailBasketSales";

        if (group) {
            url += "?group=" + group;

            if (store)
                url += "&storeId=" + store.id;
        }

        return this.http.get(url)
            .pipe(map((data: any) => {
                this.dashboardDataPoint = data;
                return (this.dashboardDataPoint != null);
            }));

    }

    public loadRetailBasketTransactionCounts(group: string, store: Store): Observable<boolean> {

        var url = "/api/Dashboard/v1/RetailBasketTransactions";

        if (group) {
            url += "?group=" + group;

            if (store)
                url += "&storeId=" + store.id;
        }

        return this.http.get(url)
            .pipe(map((data: any) => {
                this.dashboardDataPoint = data;
                return (this.dashboardDataPoint != null);
            }));

    }

    public loadMedicalAidSales(group: string, store: Store): Observable<boolean> {

        var url = "/api/Dashboard/v1/MedicalAidSales";

        if (group) {
            url += "?group=" + group;

            if (store)
                url += "&storeId=" + store.id;
        }

        return this.http.get(url)
            .pipe(map((data: any[]) => {
                this.categorySales = data;
                return (this.categorySales != null);
            }));

    }

    public loadMedicalAidVsPrivateSales(group: string, store: Store): Observable<boolean> {

        var url = "/api/Dashboard/v1/MedicalAidVsPrivateSales";

        if (group) {
            url += "?group=" + group;

            if (store)
                url += "&storeId=" + store.id;
        }

        return this.http.get(url)
            .pipe(map((data: any[]) => {
                this.categorySales = data;
                return (this.categorySales != null);
            }));

    }

    public loadGrossProfits(store: Store): Observable<boolean> {

        var url = "/api/Dashboard/v1/GrossProfit/" + store.id;

        return this.http.get(url)
            .pipe(map((data: any[]) => {
                this.grossProfits = data;
                return (this.grossProfits != null);
            }));

    }

    public loadLPPenetrationStoreID(storeId: string): boolean {
        this.storeId = storeId;
        return true;
    }

    public loadLPPenetrationYearly(storeId: string): Observable<boolean> {
        if (storeId == null) {
            storeId = this.storeId;
        }
        else {
            this.storeId = storeId;
        }
        var url = "/api/Dashboard/v1/LPPenetrationYearly/" + storeId;

        return this.http.get(url)
            .pipe(map((data: any[]) => {
                this.lpPenetration = data;
                return (this.lpPenetration != null);
            }));
    }

    public loadMainPenetrationStat(storeId: string): Observable<boolean> {
        if (storeId == null) {
            storeId = this.storeId;
        }
        else {
            this.storeId = storeId;
        }
        var url = "/api/Dashboard/v1/LPPenetrationCurrentStat/" + storeId;

        return this.http.get(url)
            .pipe(map((data: any[]) => {
                this.lpPenetrationMainStat = data;
                return (this.lpPenetrationMainStat != null);
            }));
    }
}
