import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GrossProfit } from "../../../../shared/interfaces/grossProfit";
import { Store } from "../../../../shared/interfaces/store";
import { StoreService } from "../../../../shared/services/storeService";

@Component({
    selector: 'gross-profit',
    templateUrl: 'grossProfit.component.html'
})
export class GrossProfitComponent implements OnInit {

    title: string = "";
    subtitle: string = "";

    @Input() store: Store;
    @Input() group: string;

    //private _sales: number;
    //@Input() set sales(value: number) {
    //    this._sales = value;
    //    //this.UpdateSalesChart();
    //}
    //get sales(): number {
    //    return this._sales;
    //}

    grossProfits: GrossProfit[];
    public comboChartMax: number;
    public comboChartData: Array<any>;
    public comboChartLabels: Array<any>;

    //private salesModel: string = "Sales";

    constructor(private storeService: StoreService) { }

    ngOnInit(): void {

        this.comboChartLabels = [1, 2, 3];

        this.comboChartData = [
            {
                data: [1, 2, 3],
                label: '123',
                yAxisID: 'yAxis1'
            },
            {
                data: [0.1, 0.7, 0.33],
                type:'line',
                label: '246',
                yAxisID: 'yAxis2'
            }];

        this.updateGPChart();
    }

    updateGPChart() {

        var labels: number[] = [];
        var rates: number[] = [];
        var profit: number[] = [];

        this.storeService.loadGrossProfits(this.store)
            .subscribe(s => {

                this.grossProfits = this.storeService.grossProfits;

                this.title = "Gross Profits on Lenses";
                this.subtitle = "";

                this.grossProfits.forEach(g => {

                    labels.push(g.yearMonth);
                    rates.push(g.gpRate);
                    profit.push(g.grossProfit);

                   
                });

                this.comboChartLabels = labels;

                this.comboChartData = [
                    {
                        data: profit,
                        label: 'GP Profit (R)',
                        yAxisID: 'yAxis1'
                    },
                    {
                        data: rates,
                        type: 'line',
                        fill: false,
                        label: 'GP Rate (%)',
                        yAxisID: 'yAxis2'
                    }];
               

                //this.comboChartLabels = this.grossProfits.measures[0].salesFigures.map(s => s.day);
                //this.comboChartMax = this.chartData.maxMeasure;

                //this.comboChartData = [
                //    {
                //        data: this.chartData.measures[0].salesFigures.map(s => s.sales),
                //        label: this.chartData.measures[0].title
                //    },
                //    {
                //        data: this.chartData.measures[1].salesFigures.map(s => s.sales),
                //        label: this.chartData.measures[1].title
                //    }];
            });

       


    }

    //onDownloadData() {

    //    var csvData = this.getChartData();
    //    var a = document.createElement("a");
    //    a.setAttribute('style', 'display:none;');
    //    document.body.appendChild(a);
    //    var blob = new Blob([csvData], { type: 'text/csv' });
    //    var url = window.URL.createObjectURL(blob);
    //    a.href = url;
    //    var date: Date = new Date();
    //    var strData: string = (date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate()).toString();
    //    var link: string = "GPChart_" + strData + '.csv';
    //    a.download = link.toLocaleLowerCase();
    //    a.click();

    //}


    //getChartData() {

    //    var str = '';
    //    var row = "";

    //    str = 'Day,' + this.chartData.measures[0].title + ',' + this.chartData.measures[1].title + '\r\n'

    //    for (var i = 0; i < this.lineChartLabels.length; i++) {
    //        str += this.lineChartLabels[i] + ',' + this.lineChartData[0].data[i] + ',' + this.lineChartData[1].data[i] + '\r\n';
    //    }

    //    return str;
    //}

}