import { Component } from '@angular/core';
import { AuthService } from '../../auth/authService';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {

  isLoading: boolean;
  errorMessage: string;

  public creds = {
    username: "",
    password: ""
  };

  constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.isLoading = false;
  }

  onLogin() {

    this.isLoading = true;
    // call login service
    this.auth.login(this.creds)
      .subscribe(success => {
        if (success) {
          this.router.navigate(['']);
        }
      }, err => {
        this.isLoading = false;
        this.errorMessage = "Failed to login. " + err.error;
      });

  }

  onRegister() {
    this.router.navigate(['register']);
  }

  onForgotPassword() {
    this.router.navigate(['ForgotPassword']);
  }

}
