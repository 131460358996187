import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSerializer } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { AuthGuard } from './auth/authGuard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth/authInterceptor';
import { AuthService } from './auth/authService';
import { StorageService } from './shared/services/storageService';
import { MarketShareGuard } from './auth/marketShareGuard';
import { CustomUrlSerializer } from './shared/src/trailing-slash-fix';
import { API_URL } from './auth/apiUrl';
import { environment } from '../environments/environment'; // Production: environment.prod; Development: environment; Test: environment.test
import { StoreDashboardComponent } from './views/store-dashboard/store-dashboard.component';
import { StoreLicenceFeesComponent } from './views/store-licence-fees/storeLicenceFees.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    },
    {
        path: 'store-dashboard',
        component: StoreDashboardComponent,
        data: {
            title: 'Store Dashboard'
        }
    },
    {
        path: 'store-dashboard/:token/Group/:group',
        component: StoreDashboardComponent,
        data: {
            title: 'Store Dashboard'
        }
    },
    {
        path: 'store-dashboard/:token/Store/:id',
        component: StoreDashboardComponent,
        data: {
            title: 'Store Dashboard'
        }
    },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
        },
        {
            path: 'licencefees',
            loadChildren: () => import('./views/store-licence-fees/storeLicenceFees.module').then(m => m.StoreDayEndsModule)
        },
        {
            path: 'storedayends',
            loadChildren: () => import('./views/storeDayEnds/storeDayEnds.module').then(m => m.StoreDayEndsModule)
        },
      {
        path: 'marketshare',
        loadChildren: () => import('./views/marketShare/marketShare.module').then(m => m.MarketShareModule),
        canActivate: [MarketShareGuard]
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [ RouterModule ],
  providers: [
    AuthGuard,
    MarketShareGuard,
    AuthService,
      StorageService,
      { provide: API_URL, useValue: environment.apiUrl },
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, deps: [API_URL] },
      { provide: UrlSerializer, useClass:CustomUrlSerializer }
  ]
})
export class AppRoutingModule {}
