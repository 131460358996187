import { Component, OnInit, Input } from '@angular/core';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { StoreService } from '../../../../shared/services/storeService';
import { compileInjectable } from '@angular/compiler';
import { ChartData } from '../../../../shared/interfaces/chartData';
import { DashboardDataPoint } from '../../../../shared/interfaces/dashboardDataPoint';
import { Group } from '../../../../shared/interfaces/group';
import { Store } from '../../../../shared/interfaces/store';

@Component({
    selector: 'eye-test',
    templateUrl: 'eyeTest.component.html'
})
export class EyeTestComponent implements OnInit {

    title: string = "";
    subtitle: string = "";

    @Input() store: Store;
    @Input() group: Group;

    private _eyeTests: number;
    @Input() set eyeTests(value: number) {
        this._eyeTests = value;
        //this.UpdateEyeTestChart();
    }
    get eyeTests(): number {
        return this._eyeTests;
    }

    public chartData: ChartData;
    public lineChartData: Array<any>;
    public lineChartLabels: Array<any>;
    public lineChartMax: number;

    private eyeTestModel: string = "All";

    constructor(private storeService: StoreService) { }

    ngOnInit(): void {

        this.lineChartLabels = [1, 2, 3];

        this.lineChartData = [
            {
                data: [1, 2, 3],
                label: '123'
            },
            {
                data: [1, 2, 3],
                label: '123'
            }];

        this.UpdateEyeTestChart();
        
    }

    UpdateEyeTestChart() {
        this.storeService.loadStoreEyeTestRunRate(this.eyeTestModel, this.group, this.store)
            .subscribe(s => {

                this.chartData = this.storeService.chartData;

                this.title = this.chartData.title;
                this.subtitle = this.chartData.subtitle;

                this.lineChartLabels = this.chartData.measures[0].salesFigures.map(s => s.day);
                this.lineChartMax = this.chartData.maxMeasure;

                this.lineChartData = [
                    {
                        data: this.chartData.measures[0].salesFigures.map(s => s.sales),
                        label: this.chartData.measures[0].title
                    },
                    {
                        data: this.chartData.measures[1].salesFigures.map(s => s.sales),
                        label: this.chartData.measures[1].title
                    }];

            });

    }

    onDownloadData() {

        var csvData = this.getChartData();
        var a = document.createElement("a");
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        var blob = new Blob([csvData], { type: 'text/csv' });
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        var date: Date = new Date();
        var strData: string = (date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate()).toString();
        var link: string = "eyeTests_" + this.eyeTestModel + "_" + strData + '.csv';
        a.download = link.toLocaleLowerCase();
        a.click();

    }


    getChartData() {

        var str = '';
        var row = "";

        str = 'Day,' + this.chartData.measures[0].title + ',' + this.chartData.measures[1].title + '\r\n'

        for (var i = 0; i < this.lineChartLabels.length; i++) {
            str += this.lineChartLabels[i] + ',' + this.lineChartData[0].data[i] + ',' + this.lineChartData[1].data[i] + '\r\n';
        }

        return str;
    }

}