import { Component, Input } from '@angular/core';

@Component({
  selector: 'bar-chart',
  templateUrl: 'barChart.component.html',
  styleUrls: ['barChart.component.css']
})
export class BarChartComponent {
  
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
  };

  @Input() public barChartLabels: string[];
  public barChartType = 'bar';
  public barChartLegend = true;

  @Input() private colors;

  @Input() public barChartData: any[];

}

/*
 * Market share chart settings:
 * ****************************
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  @Input() public barChartLabels: string[];
  public barChartType = 'bar';
  public barChartLegend = true;

  @Input() private colors;

  @Input() public barChartData: any[];
 */
