import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LPPenetrationYearly } from "../../../../shared/interfaces/lpPenetrationYearly";
import { Store } from "../../../../shared/interfaces/store";
import { StoreService } from "../../../../shared/services/storeService";

@Component({
    selector: 'lp-penetration',
    templateUrl: 'lpPenetration.component.html'
})
export class LPPenetrationComponent implements OnInit {

    title: string = "";
    subtitle: string = "";

    @Input() store: Store;
    @Input() group: string;
    @Input() storeId: string;
    
    lpPenetration: LPPenetrationYearly[];
    public comboChartMax: number;
    public comboChartData: Array<any>;
    public comboChartLabels: Array<any>;


    constructor(private storeService: StoreService) { }

    ngOnInit(): void {
        this.comboChartLabels = [1, 2, 3];

        this.comboChartData = [
            {
                data: [1, 2, 3],
                label: '123',
                yAxisID: 'yAxis1'
            },
            {
                data: [0.1, 0.7, 0.33],
                type: 'line',
                label: '246',
                yAxisID: 'yAxis2'
            }];

        this.updateGPChart();
    }

    updateGPChart() {

        var labels: number[] = [];
        var rates: number[] = [];
        var profit: number[] = [];
        this.storeService.loadLPPenetrationYearly(this.storeId)
            .subscribe(s => {

                this.lpPenetration = this.storeService.lpPenetration;

                this.title = "Lens Portal Penetration";
                
                var total = 0;
                var totalLensPortal = 0;

                this.lpPenetration.forEach(g => {
                    var percentage = Math.round((g.lensPortalOrders / g.total) * 10000.0) / 100.0;
                    labels.push(g.yearMonth);
                    rates.push(percentage);
                    profit.push(g.lensPortalOrders);

                    total += g.total;
                    totalLensPortal += g.lensPortalOrders;
                });
                var totalPercentage = (totalLensPortal / total) * 100.0;
                this.subtitle = totalPercentage.toFixed(2) + "% over the last year.";

                this.comboChartLabels = labels;

                this.comboChartData = [
                    {
                        data: profit,
                        label: 'Lens Portal Orders',
                        yAxisID: 'yAxis1'
                    },
                    {
                        data: rates,
                        type: 'line',
                        fill: false,
                        label: 'Lens Portal Penetration (%)',
                        yAxisID: 'yAxis2'
                    }];
            });
    }
}