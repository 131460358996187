import { Component, OnInit, Input } from '@angular/core';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { StoreService } from '../../../../shared/services/storeService';


@Component({
    selector: 'growth',
    templateUrl: 'growth.component.html'
})
export class GrowthComponent implements OnInit {

    title: string = "";
    @Input() subtitle: string;


    //// mainChart

    //public mainChartElements = 27;
    //public mainChartData1: Array<number> = [];
    //public mainChartData2: Array<number> = [];
    //public mainChartData3: Array<number> = [];

    //public mainChartData: Array<any> = [
    //    {
    //        data: this.mainChartData1,
    //        label: 'Current'
    //    },
    //    {
    //        data: this.mainChartData2,
    //        label: 'Previous'
    //    },
    //    {
    //        data: this.mainChartData3,
    //        label: 'BEP'
    //    }
    //];
    ///* tslint:disable:max-line-length */
    //public mainChartLabels: Array<any> = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Monday', 'Thursday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    ///* tslint:enable:max-line-length */
    //public mainChartOptions: any = {
    //    tooltips: {
    //        enabled: false,
    //        custom: CustomTooltips,
    //        intersect: true,
    //        mode: 'index',
    //        position: 'nearest',
    //        callbacks: {
    //            labelColor: function (tooltipItem, chart) {
    //                return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor };
    //            }
    //        }
    //    },
    //    responsive: true,
    //    maintainAspectRatio: false,
    //    scales: {
    //        xAxes: [{
    //            gridLines: {
    //                drawOnChartArea: false,
    //            },
    //            ticks: {
    //                callback: function (value: any) {
    //                    return value.charAt(0);
    //                }
    //            }
    //        }],
    //        yAxes: [{
    //            ticks: {
    //                beginAtZero: true,
    //                maxTicksLimit: 5,
    //                stepSize: Math.ceil(250 / 5),
    //                max: 250
    //            }
    //        }]
    //    },
    //    elements: {
    //        line: {
    //            borderWidth: 2
    //        },
    //        point: {
    //            radius: 0,
    //            hitRadius: 10,
    //            hoverRadius: 4,
    //            hoverBorderWidth: 3,
    //        }
    //    },
    //    legend: {
    //        display: false
    //    }
    //};
    //public mainChartColours: Array<any> = [
    //    { // brandInfo
    //        backgroundColor: hexToRgba(getStyle('--info'), 10),
    //        borderColor: getStyle('--info'),
    //        pointHoverBackgroundColor: '#fff'
    //    },
    //    { // brandSuccess
    //        backgroundColor: 'transparent',
    //        borderColor: getStyle('--success'),
    //        pointHoverBackgroundColor: '#fff'
    //    },
    //    { // brandDanger
    //        backgroundColor: 'transparent',
    //        borderColor: getStyle('--danger'),
    //        pointHoverBackgroundColor: '#fff',
    //        borderWidth: 1,
    //        borderDash: [8, 5]
    //    }
    //];
    //public mainChartLegend = false;
    //public mainChartType = 'line';

    public barChartLabels: string[];
    private colors;
    public barChartData: any[];

    private _volumeGrowthAll: number;
    @Input() set volumeGrowthAll(value: number) {
        this._volumeGrowthAll = value;
        this.barChartData = [{ label: "Volume (%)", data: [this.volumeGrowthAll, this.volumeGrowthRetail] }, { label: "Value (%)", data: [this.valueGrowthAll, this.valueGrowthRetail] }];
    }
    get volumeGrowthAll(): number { return this._volumeGrowthAll; }

    private _volumeGrowthRetail: number;
    @Input() set volumeGrowthRetail(value: number) {
        this._volumeGrowthRetail = value;
        this.barChartData = [{ label: "Volume (%)", data: [this.volumeGrowthAll, this.volumeGrowthRetail] }, { label: "Value (%)", data: [this.valueGrowthAll, this.valueGrowthRetail] }];
    }
    get volumeGrowthRetail(): number { return this._volumeGrowthRetail; }

    private _valueGrowthAll: number;
    @Input() set valueGrowthAll(value: number) {
        this._valueGrowthAll = value;
        this.barChartData = [{ label: "Volume (%)", data: [this.volumeGrowthAll, this.volumeGrowthRetail] }, { label: "Value (%)", data: [this.valueGrowthAll, this.valueGrowthRetail] }];
    }
    get valueGrowthAll(): number { return this._valueGrowthAll; }

    private _valueGrowthRetail: number;
    @Input() set valueGrowthRetail(value: number) {
        this._valueGrowthRetail = value;
        this.barChartData = [{ label: "Volume (%)", data: [this.volumeGrowthAll, this.volumeGrowthRetail] }, { label: "Value (%)", data: [this.valueGrowthAll, this.valueGrowthRetail] }];
    }
    get valueGrowthRetail(): number { return this._valueGrowthRetail; }

    constructor(private storeService: StoreService) { }

    ngOnInit(): void {

        this.title = "Sales Growth";
        this.barChartLabels = ["All Transactions","Retail Basket"];
        this.barChartData = [{ label: "Volume (%)", data: [this.volumeGrowthAll, this.volumeGrowthRetail] }, { label: "Value (%)", data: [this.valueGrowthAll, this.valueGrowthRetail] }];
        this.colors = [];

        //// generate random values for mainChart
        //for (let i = 0; i <= this.mainChartElements; i++) {
        //    this.mainChartData1.push(this.random(50, 200));
        //    this.mainChartData2.push(this.random(80, 100));
        //    this.mainChartData3.push(65);
        //}
    }

    //public random(min: number, max: number) {
    //    return Math.floor(Math.random() * (max - min + 1) + min);
    //}
}