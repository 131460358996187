import { Component, OnInit, Input } from '@angular/core';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';


@Component({
    selector: 'widget-bar-chart',
    templateUrl: 'widgetBarChart.component.html'
})
export class WidgetBarChartComponent {

    // barChart
    @Input() public barChartMax: number;
    @Input() public barChartData: Array<any>;
    @Input() public barChartLabels: Array<any>;

    //public barChartData: Array<any> = [
    //    {
    //        data: [78, 81, 80, 45, 34, 12, 40, 78, 81, 80, 45, 34, 12, 40, 12, 40],
    //        label: 'Series A',
    //        barPercentage: 0.6,
    //    }
    //];
    //public barChartLabels: Array<any> = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16'];
    public barChartOptions: any = {
        tooltips: {
            enabled: false,
            custom: CustomTooltips
        },
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                display: false,
            }],
            yAxes: [{
                display: false
            }]
        },
        legend: {
            display: false
        }
    };
    public barChartColours: Array<any> = [
        {
            backgroundColor: 'rgba(255,255,255,.3)',
            borderWidth: 0
        }
    ];
    public barChartLegend = false;
    public barChartType = 'bar';

}
