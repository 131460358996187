import { Component } from '@angular/core';
import { UserService } from '../../shared/services/userService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html'
})
export class RegisterComponent {

  errorMessage: string = "";
  passwordConfirmationFailed = false;

  public creds = {
    id: null,
    firstName: "",
    surname: "",
    email: "",
    username: "",
    password: "",
    isEnabled: false
  };

  constructor(private userService: UserService, private router: Router) {
  }


  public passwordConfirmation = {
    newPassword: "",
    newPasswordConfirmation: ""
  };

  confirmPassword() {
    if (this.creds.password === this.passwordConfirmation.newPasswordConfirmation) {
      this.passwordConfirmationFailed = false;
    } else {
      this.passwordConfirmationFailed = true;
    }
  }

  onRegister() {

    this.errorMessage = "";

    this.userService.register(this.creds)
      .subscribe(data => {

        alert("Your registration is almost complete. The CDB BI administrator needs to authorise your account. Following that your account will be active for you to use.");
        this.creds.firstName = "";
        this.creds.surname = "";
        this.creds.email = "";
        this.creds.username = "";
        this.creds.password = "";
        this.passwordConfirmation.newPassword = "";
        this.passwordConfirmation.newPasswordConfirmation = "";
        this.errorMessage = "";

        this.router.navigate(['login']);

      }, err => {
        if (err.error.length <= 500) {
          this.errorMessage = "Failed to register. " + err.error;
        }
        else {
          this.errorMessage = "Failed to register. Please try again.";
        }
      });
   
  }

}
