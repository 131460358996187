import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, CommonModule, registerLocaleData } from '@angular/common';
import localFR from '@angular/common/locales/fr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar'; 
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
 
import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

import { StoreService } from './shared/services/storeService';
import { ChartControlsService } from './shared/services/chartControlService';

registerLocaleData(localFR);

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { FormsModule } from '@angular/forms';
import { UserService } from './shared/services/userService';
import { StoreDashboardComponent } from './views/store-dashboard/store-dashboard.component';
import { SalesDashboardComponent } from './views/store-dashboard/salesDashboard/salesDashboard.component';
import { WidgetComponent } from './views/store-dashboard/salesDashboard/widget.component';
import { EyeTestComponent } from './views/store-dashboard/salesDashboard/dashboardCharts/eyeTest.component';
import { GrowthComponent } from './views/store-dashboard/salesDashboard/dashboardCharts/growth.component';
import { MedicalAidComponent } from './views/store-dashboard/salesDashboard/dashboardCharts/medicalAid.component';
import { SalesRunRateComponent } from './views/store-dashboard/salesDashboard/dashboardCharts/salesRunRate.component';
import { LineChartComponent } from './views/charts/line-chart/lineChart.component';
import { BarChartComponent } from './views/charts/barChart/barChart.component';
import { ProgressBarChartComponent } from './views/charts/progress-bar-chart/progressBarChart.component';
import { WidgetBarChartComponent } from './views/charts/widget-bar-chart/widgetBarChart.component';
import { WidgetLineChartComponent } from './views/charts/widget-line-chart/widgetLineChart.component';
import { GrossProfitComponent } from './views/store-dashboard/salesDashboard/dashboardCharts/grossProfit.component';
import { ComboChartComponent } from './views/charts/combo-chart/comboChart.component';
import { SettingService } from './shared/services/settingService';
import { LPPenetrationComponent } from './views/store-dashboard/salesDashboard/dashboardCharts/lpPenetration.component';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    HttpClientModule,
        ChartsModule,
        BsDropdownModule,
    ButtonsModule.forRoot()
    ],
  declarations: [
      AppComponent,
      DefaultLayoutComponent,
    //...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
      RegisterComponent,
      StoreDashboardComponent,
      SalesDashboardComponent,
      WidgetComponent,
      EyeTestComponent,
      GrowthComponent,
      MedicalAidComponent,
      SalesRunRateComponent,
      LineChartComponent,
      BarChartComponent,
      ProgressBarChartComponent,
      WidgetBarChartComponent,
      WidgetLineChartComponent,
      GrossProfitComponent,
      ComboChartComponent,
      LPPenetrationComponent
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  },
      { provide: LOCALE_ID, useValue: 'fr-FR' },
    StoreService,
    ChartControlsService,
      UserService,
      SettingService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
