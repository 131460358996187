import { Component, OnInit, Input } from '@angular/core';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';


@Component({
    selector: 'widget-line-chart',
    templateUrl: 'widgetLineChart.component.html'
})
export class WidgetLineChartComponent {

    // lineChart

    public lineChartElements = 27;

    @Input() public lineChartMax: number;
    @Input() public lineChartData: Array<any>;
    @Input() public lineChartLabels: Array<any>;

    public lineChartOptions: any = {
        tooltips: {
            enabled: false,
            custom: CustomTooltips
        },
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                display: false
            }],
            yAxes: [{
                display: false
            }]
        },
        elements: {
            line: {
                borderWidth: 2
            },
            point: {
                radius: 0,
                hitRadius: 10,
                hoverRadius: 4,
            },
        },
        legend: {
            display: false
        }
    };
    public lineChartColours: Array<any> = [
        {
            backgroundColor: 'rgba(255,255,255,.2)',
            borderColor: 'rgba(255,255,255,.55)',
        }
    ];
    public lineChartLegend = false;
    public lineChartType = 'line';


}