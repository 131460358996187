import { Component, Input, OnInit } from '@angular/core';
import { CategorySales } from '../../../shared/interfaces/categorySales';

@Component({
    selector: 'progress-bar-chart',
    templateUrl: 'progressBarChart.component.html'
})
export class ProgressBarChartComponent implements OnInit {

    @Input() chartData: CategorySales[];
    @Input() colour: string;

    ngOnInit(): void {
        //this.chartData = [{ id: "Medical Aid", day: 25, sales: 1223452 }, { id: "Private", day: 37, sales: 123441 }]
        //this.colour = "bg-warning";
    }

    

}