import { Component, OnInit } from '@angular/core';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: 'store-dashboard.component.html'
})
export class StoreDashboardComponent implements OnInit {

    group: string;
    storeId: string;

    ngOnInit(): void {

        this.group = this.route.snapshot.paramMap.get('group');
        this.storeId = this.route.snapshot.paramMap.get('id');
        var token = this.route.snapshot.paramMap.get('token');
        
        localStorage.setItem('token', token);
    }

    constructor(private route: ActivatedRoute, private router: Router) { }
}