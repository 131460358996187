import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
    {
        name: 'Dashboard',
        url: '/dashboard',
        icon: 'icon-speedometer',
        attributes: [
            'Basic User'
        ]
    },
    {
        name: 'Store Licence Fees',
        url: '/licencefees',
        icon: 'cui-dollar',
        attributes: [
            'Finance'
        ]
    },
    //{
    //    title: true,
    //    name: 'Updater',
    //    attributes: [
    //        'Basic User'
    //    ]
    //},
    {
        name: 'Store Day Ends',
        url: '/storedayends',
        icon: 'cui-moon',
        attributes: [
            'Basic User'
        ]  
    },
    //{
    //    title: true,
    //    name: 'General',
    //    attributes: [
    //        'Basic User'
    //    ]
    //},
    {
        name: 'Market Share',
        url: '/marketshare',
        icon: 'icon-chart',
        attributes: [
            'Market Share User'
        ]
    }
];
