import { Component, OnInit } from '@angular/core';
import { navItems } from '../../_nav';
import { AuthService } from '../../auth/authService';
import { Role } from '../../shared/interfaces/role';
import { User } from '../../shared/interfaces/user';
import { log } from 'util';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {

  public sidebarMinimized = false;
  public navItems = navItems;
  public roles: Role[] = [];
  public user: User;

  constructor(private authService: AuthService) { }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  onLogout() {
    this.authService.logout();
  }

  getRoles() {
    this.authService.getRoles()
      .subscribe(r => {

        if (r) {
            this.roles = this.authService.roles;

            var navItems = this.navItems.filter(n => n.attributes == null);

            this.navItems.filter(n => n.attributes != null).forEach(n => {
                if (this.roles.find(r => r.isAssigned && n.attributes.includes(r.name))) {
                    navItems.push(n);
                }
            });

            this.navItems = navItems;
        }

      });
  }

  ngOnInit(): void {
    this.getRoles();
    this.getUser();
  }

  getUser() {
    this.authService.getUser()
      .subscribe(s => {
        if (s) {
          this.user = this.authService.user;
        }
      });
  }

}
